import React from 'react';
// import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'
import { BookContext } from '../../store/BookContext'
import { NavigationContext } from '../../store/NavigationContext'
import { axiosConfig } from '../../utils/axiosConfig';
import pictoAccepted from '../../assets/icon-accepter.svg'


// import styles from './TerminateSale.module.css';

function TerminateSale() {
    // const { dispatch } = React.useContext(NavigationContext);
    const { authState } = React.useContext(AuthContext);
    const { bookState } = React.useContext(BookContext);
    const { navigationDispatch } = React.useContext(NavigationContext);

    const [ query, setQuery ] = React.useState('');

    React.useEffect(() => { 
        const SendToAdminBO = () => {
            setQuery('')
            axiosConfig.post('apiFront/refurb/create',{
                        "user": authState.user._id,
                        "customer": authState.customer._id,
                        "store": authState.store._id,
                        "books": bookState.cart,
                    })
                    .then(response=>{
                        if (response.data) {
                            navigationDispatch({type:'cartValidated', payload: response.data})
                        }
                    })
                // fetch(process.env.REACT_APP_API_URL  + "/apiFront/refurb/create", {
                //     method: 'POST',
                //     headers: {
                //         'Content-Type':'application/json',
                //         'Authorization': authState.store.token
                //     },
                //     body: JSON.stringify({
                //         "user": authState.user._id,
                //         "customer": authState.customer._id,
                //         "store": authState.store._id,
                //         "books": bookState.cart,
                //     })
                // })
                // .then(res => res.json())
                // .then(data => {
                //     if (!data) {
                        
                //     }else{
                //         navigationDispatch({type:'cartValidated', payload: data})
                //     }
                // })
        }
            
        if (query !== '') {
            SendToAdminBO()
        }
    }, [query, authState.customer, authState.store, authState.user, bookState.cart, navigationDispatch])

  return (
    <>
        <button 
            // className={'btn btn-s btn-blue'}
            className={'newBtn greenDecitre white'}
            onClick={ () => setQuery('send') }
        >
            <img 
                className={'m10-right'}
                alt='picto accepté' src={pictoAccepted}
            />
                J'ai terminé
        </button>
    </>
    );
}

export default TerminateSale;                   
                    
                    
                    
                    
                    