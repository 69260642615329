import React from 'react';
import styles from './FlashMessage.module.css';

export default function Flash (){
    
    return (
        <div className={styles.flashMessageBoX} role="alert" >
            <svg xmlns="http://www.w3.org/2000/svg" className={styles.symbol} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                <path strokeLinecap="round" strokeLinejoin="round" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <strong className="font-bold mr-1 ml-1">
                Problème réseau, 
            </strong> 
            vérifiez l'état de votre connexion Internet.
        </div>
    )
}