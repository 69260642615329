import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'
import { axiosConfig } from '../../utils/axiosConfig';
import styles from './UserLogin.module.css';
// var axiosConfig = require('../../utils/axiosConfig')
import pictoAccepted from '../../assets/icon-accepter.svg'

function UserLogin() {
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { authState, authDispatch } = React.useContext(AuthContext);

    const [ step, setStep ] = React.useState(1);
    const [ call ] = React.useState(true);
    const [ error ] = React.useState('');
    const [ errorLogin, setErrorLogin ] = React.useState('');
    const [ user, setUser ] = React.useState({
        username: '',
        password: ''
    });
    const [ query, setQuery ] = React.useState('');

   
    React.useEffect(() => {
        const loginUser = () =>
            axiosConfig.post(`${process.env.REACT_APP_API_URL}/apiFront/user/login`,
                query
            )
            .then(resp=>{
                if (resp.data.status) {
                    authDispatch({type:'userAuth', payload: resp.data.user})
                    navigationDispatch({type:'userLoggedIn'})
                }else{
                    setErrorLogin('Identifiant ou mot de passe incorrect')
                }
            })
            .catch(error => {
                setQuery('')
                console.log(error);
            });
           

        if(query !== '') {
            setErrorLogin('')
            loginUser();
        }
    }, [query, navigationDispatch, authDispatch, authState])
    
  return (
    <>  
        {step === 1 ?

            <>
                {call === false ?
                    <>
                    <h2 className={styles.title}>Votre libraire Decitre va valider votre liste de reprise et l'état de vos livres</h2>
                    {error !== '' ?
                        <span className={"alert alert-danger " + styles.error} role="alert">
                            {error}
                        </span>
                    :null}
                    </>
                :
                    <>
                        <h2 className={styles.title}>
                            Votre libraire Decitre va vérifier l'état de vos livres
                            et valider votre liste de reprise.
                        </h2>
                        <button 
                                className={"newBtn white greenDecitre"}
                                onClick={ () => setStep(2)}
                            >
                                <img className={"m10-right"} src={pictoAccepted} alt='bouton accpeter'></img>
                                Valider
                            </button>
                    </>
                }
            </>

        :step === 2 ?

            <>
               <h2 className={styles.title}>Identification du libraire Decitre</h2>
                <form
                    className={styles.loginForm}
                    onSubmit={e => {
                        e.preventDefault();
                        setQuery(user)
                }}>
                    <div className={"form-group " + styles.inputBox}>
                        <label >Nom d'utilisateur</label>
                        <input type="text" className="form-control" 
                            placeholder="Nom d'utilisateur"
                            onChange={e => setUser({...user, username: e.target.value}) }
                            value={user.username || ''}
                            required
                            />
                    </div>
                    <div className={"form-group " + styles.inputBox}>
                        <label >Mot de passe</label>
                        <input type="password" className="form-control" 
                        placeholder="Password"
                        onChange={e => setUser({...user, password: e.target.value}) }
                        value={user.password || ''}
                        required
                        />
                    </div>
                    {errorLogin !== '' ?
                        <span className={"alert alert-danger " + styles.error} role="alert">
                            {errorLogin}
                        </span>
                    :null}
                    <button
                        type="submit" 
                        className={"newBtn white greenDecitre " + styles.btn}>

                            Se connecter
                        </button>
                </form> 
                
            </>

        :null}
    </>
    );
}

export default UserLogin;                   
                    
                    
                    
                    
                    