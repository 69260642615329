import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'

import iconReturn from '../../assets/icon-retour-blanc.svg'

import styles from './ForgotPassword.module.css';
import { axiosConfig } from '../../utils/axiosConfig';

function ForgotPassword() {
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { authState, authDispatch } = React.useContext(AuthContext);
    const [ step, setStep ] = React.useState(0);
    const [ error, setError ] = React.useState('');

    const [ customer, setCustomer ] = React.useState({
        email: '',
        mdpTemp: '',
        mdpNew: '',
        mdpConf: ''
    });

    const [ queryRecover, setQueryRecover ] = React.useState('');
    const [ queryChange, setQueryChange ] = React.useState('');

    React.useEffect(() => {
        const sendTempPassword = () =>{
        axiosConfig.post("/apiFront/customer/reinitPassword",
            queryRecover
        )
        .then(response=>{
            if (!response.data || response.data.error !== 0) {
                if(response.data.redirectToPresta){
                    window.open("https://www.ammareal.fr/recuperation-mot-de-passe");
                    navigationDispatch({type:'customerLogin'})
                  }else{
                    setError('Aucun compte correspondant à '+ customer.email +' n\'a été trouvé.')
                    setCustomer({email: ''})
                  }
            }else{
                setStep(1)
            }
        })
        }
        if (queryRecover !== '') {
            setError('')
            sendTempPassword();
            setQueryRecover('')
        }
    }, [queryRecover,customer.email, navigationDispatch, authDispatch, authState])


    React.useEffect(() => {
        const changePassword = () =>{
        axiosConfig.post('apiFront/customer/changePassword', queryChange)
        .then(response =>{
            if (!response.data || response.data.error !== 0) {
                setError('Un problème est survenue lors du changement de votre mot de passe')
                
            }else{
                setQueryChange('')
                // setStep(1)
                // console.log(response.data)
                authDispatch({type:'customerAuth', payload: response.data.customer})
                navigationDispatch({type:'customerLoggedIn'})
            }
            
            })
        }
        if (queryChange !== '') {
            setError('')
            if(customer.mdpNew !== customer.mdpConf){
                setError('Les mots de passe ne correspondent pas')
                setQueryChange('')
            }else{
                changePassword();
            }  
        }
    }, [queryChange, navigationDispatch, authDispatch, authState, customer.mdpNew, customer.mdpConf])

    
  return (
      
    <>  
    {step === 0 ?

        <>
            <h2 className={"titleGreen "}>Renseigner votre adresse mail pour recevoir un mot de passe temporaire</h2>
            <form
                className={styles.form}
                onSubmit={e => {
                    e.preventDefault();
                    setQueryRecover(customer)
            }}>
                <div className={"form-group " + styles.inputBox}>
                    <label >Adresse e-mail</label>
                    <input type="email" className="form-control" 
                        placeholder="Votre adresse e-mail"
                        onChange={e => setCustomer({...customer, email: e.target.value.toLowerCase()}) }
                        value={customer.email || ''}
                        required
                        />
                </div>
                
                <button type="submit" 
                    className={"newBtn greenDecitre white " + styles.btn }
                >
                        Réinitialiser
                </button>
            </form>
                {error !== '' ?
                    <span className={"alert alert-danger " + styles.error} role="alert">
                        {error}
                    </span>
                :null}
            <br></br>
            <button 
                // className={'btn btn-s btn-blue ' + styles.btn}
                className={'newBtn white grayDecitre ' + styles.returnBox}
                onClick={ () => navigationDispatch({type:'goCheckout'})}

            >
                <img 
                    className={'m10-right'}
                    src={iconReturn} alt='icone retour'></img>
                retour
            </button>
        </>

    :step === 1 ?

        <>    
            <h2 className={"titleGreen"}>Changement de mot de passe</h2>
            <span className={"alert alert-success " + styles.error} role="alert">
                Un nouveau mot de passe temporaire vient de vous être envoyer à votre adresse mail
            </span>    
            <form
                className={styles.form}
                onSubmit={e => {
                    e.preventDefault();
                    setQueryChange(customer)
            }}>
                <div className={"form-group " + styles.inputBox}>
                    <label >Mot de passe temporaire</label>
                    <input type="password" className="form-control" 
                        placeholder="Mot de passe temporaire"
                        onChange={e => setCustomer({...customer, mdpTemp: e.target.value}) }
                        value={customer.mdpTemp || ''}
                        required
                        />
                </div>

                <div className={"form-group " + styles.inputBox}>
                    <label >Nouveau mot de passe</label>
                    <input type="password" className="form-control" 
                        placeholder="Nouveau mot de passe"
                        pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}"
                        onChange={e => setCustomer({...customer, mdpNew: e.target.value}) }
                        value={customer.mdpNew || ''}
                        required
                        />
                    <small id="passwordHelp" className="form-text text-muted">Minimum 8 caractères, 1 majuscule et 1 chiffre</small>

                </div>

                <div className={"form-group " + styles.inputBox}>
                    <label >Confirmation nouveau mot de passe</label>
                    <input type="password" className="form-control" 
                        placeholder="Confirmation mot de passe"
                        pattern="(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])\S{8,}"
                        onChange={e => setCustomer({...customer, mdpConf: e.target.value}) }
                        value={customer.mdpConf || ''}
                        required
                        />
                </div>
                {error !== '' ?
                <span className={"alert alert-danger " + styles.error} role="alert">
                    {error}
                </span>
            :null}
                <div className={styles.btnBox}>
                    <button type="submit" 
                        className={"noMargin newBtn greenDecitre white"}
                    >
                        Réinitialiser
                    </button>
                    <button 
                        className={"noMargin " + styles.resendBtn}
                        onClick={ () => setQueryRecover(customer)}
                    >
                        Renvoyer le mot de passe temporaire
                    </button>
                </div>
            </form>
            
            <br></br>
            
        </>

    : null }

    </>
    );
}

export default ForgotPassword;                   
                    
                    
                    
                    
                    