import React from 'react';

import Header from '../Header/Header';
import Cart from '../Cart/Cart';
import Footer from '../Footer/Footer'
import LandingPage from '../../page/LandingPage/LandingPage'
import ScanPage from '../../page/ScanPage/ScanPage'
import CheckoutPage from '../../page/CheckoutPage/CheckoutPage'
import IntroPage from '../../page/IntroPage/IntroPage';
import styles from './Layout.module.css'

//Navigation context
import { NavigationContext } from '../../store/NavigationContext' 

function Layout() {
  const { navigationState } = React.useContext(NavigationContext)
  return (
    <div className={styles.container}>
      
    { !navigationState.displayIntroPage.status ? <Header/> : null }
    { navigationState.displayIntroPage.status ? <IntroPage/> : null } 
    { (!navigationState.displayLanding && !navigationState.displayIntroPage.status ) ? <Cart/> : null }
      <LandingPage/>
      <ScanPage/>
      <CheckoutPage/>
      <Footer/>
      
    </div>
  );
}

export default Layout;
