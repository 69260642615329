import React from 'react';
import styles from './Header.module.css';
import { AuthContext } from '../../store/AuthContext'
import { BookContext } from '../../store/BookContext'
import { NavigationContext } from '../../store/NavigationContext'
import FeatherIcon from 'feather-icons-react';
import LogoSellYourBooks from '../../assets/logo-revendezvoslivres.svg'

function Layout() {
  const { authDispatch } = React.useContext(AuthContext);
  const { bookDispatch } = React.useContext(BookContext);
  const { navigationDispatch } = React.useContext(NavigationContext);
  
  function refreshPage(){ 
    window.location.reload(); 
  }
  return (
    <nav className={"navbar " + styles.nav}>
      <div className={styles.header}>
        <img className={styles.logo} alt='logo revente livres' src={LogoSellYourBooks}></img>
        <h1 className={styles.mainTitle}>Reprise de livres d’occasion</h1>
      </div>
      <button 
        className={'newBtn grayDarkDecitre white'}
        onClick={ () => {
          authDispatch({ type:'reset' });
          navigationDispatch({ type:'reset' });
          bookDispatch({ type:'reset' })  
          refreshPage()
        }}>
        <FeatherIcon className={styles.leftarrow} icon='arrow-left-circle' size="30"></FeatherIcon>
        Recommencer</button>
    </nav>
  );
}

export default Layout;
