import React from 'react';
import { BookContext } from '../../store/BookContext'
import { NavigationContext } from '../../store/NavigationContext'
import {scanAxios} from '../../utils/axiosConfig';
import styles from './ScanInput.module.css';
import scanImg from '../../assets/picto-scan-code-barre-decitre.svg'
import FeatherIcon from 'feather-icons-react';
import ReCAPTCHA from "react-google-recaptcha";
import axios from 'axios';


function ScanInput() {
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const { navigationDispatch } = React.useContext(NavigationContext);
    const [ search, setSearch ] = React.useState('');
    const [ query, setQuery ] = React.useState('');
    const [ error, setError ] = React.useState(null);
    const [challengeSuccess, setChallengeSuccess] = React.useState(true);

    const recaptchaRef = React.useRef(null)

    React.useEffect(()=>{
        var bookNumber = bookState.cart.length
        var launchIsHumanControl = bookNumber % process.env.REACT_APP_CAPTCHA_THRESHOLD_BOOK;
        if (launchIsHumanControl === 0){
            setChallengeSuccess(false)
        }
    },[bookState])

    React.useEffect(() => {
        const fetchBook = (retryNumber) =>{
        scanAxios.post(`${process.env.REACT_APP_API_URL}/apiIframe/getPrice`,{
            isbn: query,
            appId: 'borne'
        })
            .then(function (response) {
                // console.log(response)
                if (response.data.status === 'error') {
                    
                    if(response.data.code && response.data.code === 501){
                        setError('Numéro non reconnu !')
                    }else {
                        setError(`Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter. (Erreur ${response.data.code})`)
                    }
                    setSearch('')
                }else{
                    setSearch('')
                    if (!response.data.isbn13) {
                      bookDispatch({type:'currentBook', payload:{...response.data, isbn13: query}})
                    }else {
                      bookDispatch({type:'currentBook', payload:response.data})
                    }
                }
            })
            .catch(function (err) {
                setSearch('')
                if(retryNumber !==0) {
                    setTimeout(()=>{
                      fetchBook(retryNumber - 1)
                    },3000)
                  }else{
                    setError(`Un problème est survenu, réessayez dans un instant. Si le problème persiste, merci de nous contacter. (Erreur ${err.toString().replace(/\D/g, '')})`)
                  }
            });
        }

        if (query !== '' && challengeSuccess) {
            fetchBook(2)
        }

    }, [query, bookDispatch, navigationDispatch, challengeSuccess])

    React.useEffect(() => {
        const CheckISBN = async () => {
            if (search.length === 13 ) {
                setQuery(search)
            }
          };
        CheckISBN();
    });

    const captchaSubmit = async () => {
        const captchaToken = await recaptchaRef.current.getValue();
        recaptchaRef.current.reset();
        // Pass this token to your server for validation...
        await axios.post(
            `${process.env.REACT_APP_API_URL}/apiFront/captcha`,
          { 
            captchaToken
          }
        ).then(res=>{
            setChallengeSuccess(res.data.success)
        })
      }

  return (
    <div className={styles.container}>
        <form 
            // className={'container ' + styles.form}
            className={styles.form}
            onSubmit={e => {
                e.preventDefault();
                if(search.length === 13 ){
                    setQuery(search)
                }else{
                    setError("Nous n'acceptons que les codes ISBN à 13 chiffres")
                }
            }}>
            <input
                className={styles.input}
                type='number'
                placeholder="9788437601113"
                autoFocus
                value={search}
                onChange={e =>{setError(null);setSearch(e.target.value) }}
            />
            
            <button 
                className="btn"
                type="submit"
            >
                <img src={scanImg} alt='bouton scan'></img>
            </button>
        </form>
        {!challengeSuccess?
                <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    // size="invisible"
                    onChange={captchaSubmit}
                />
                :
                null
            }
        {error?
            <div  className={styles.error} >
                <FeatherIcon icon='alert-triangle'  size="40"></FeatherIcon>
                <span>
                   {error}
                </span>
            </div>  
        :
            null
        }
        
    </div>
  );
}

export default ScanInput;
