import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'

import DatePicker from "react-datepicker";
import { registerLocale } from  "react-datepicker";
import fr from 'date-fns/locale/fr';

import "react-datepicker/dist/react-datepicker.css";
import styles from '../CustomerSignup/CustomerSignup.module.css';
import { axiosConfig } from '../../utils/axiosConfig';
// var axiosConfig = require('../../utils/axiosConfig')
import iconReturnWhite from '../../assets/icon-retour-blanc.svg' 
import pictoAccepted from '../../assets/icon-accepter.svg'

registerLocale('fr', fr)

function CustomerToBeUpdate() {
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { authState, authDispatch } = React.useContext(AuthContext);
    const [ error, setError ] = React.useState('');
    const [ step, setStep ] = React.useState(1);
    const [ form, setForm ] = React.useState({
        appellation: '',
        firstName: authState.customer.firstName,
        lastName: authState.customer.lastName,
        address: '',
        postcode: '',
        city: '',
        country: '',
        personalIdType: '',
        personalIdNumber: '',
        personalIdDeliveryDate: '',
        personalIdDeliveryFrom: '',
        email: authState.customer.email,
        username: authState.customer.username,
        prestashopId: authState.customer.prestashopId,
    });
    const [ query, setQuery ] = React.useState('');
console.log("UPDATE", authState)
    React.useEffect(() => {
        const updateCutomer = () =>
        axiosConfig.post(`${process.env.REACT_APP_API_URL}/apiFront/customer/update`,query)
            .then(response => {
                if (response.data.status === false) {
                    if(response.data.error.name === 'UserExistsError'){
                        setError('Un compte avec cette adresse mail existe déjà')
                    }else{
                        setError('Une erreur est survenue lors de la création du compte')
                    }
                }else{
                    setQuery('');
                    authDispatch({type:'customerAuth', payload: response.data});
                    navigationDispatch({type:'customerLoggedIn'});
                }
            })
            .catch(error=>{
                setQuery('')
                console.log("error",error)
            })
            // fetch(process.env.REACT_APP_API_URL + "/apiFront/customer/create", {
            //     method: 'post',
            //     headers: {
            //         'Content-Type':'application/json',
            //         'Authorization': authState.store.token
            //     },
            //     body: JSON.stringify( query )
            // })
            // .then(res => res.json())
            // .then(data => {
            //     if (data.status === false) {
            //         console.log(data);
                    
            //         if(data.error.name === 'UserExistsError'){
            //             setError('Un compte avec cette adresse mail existe déjà')
            //         }else{
            //             setError('Une erreur est survenue lors de la création du compte')
            //         }
            //     }else{
            //         setQuery('')
            //         authDispatch({type:'customerAuth', payload: data})
            //         navigationDispatch({type:'customerLoggedIn'})
            //     }
            // })
        if (query !== '') {
            updateCutomer();
        }
    }, [query, navigationDispatch, authDispatch, authState])
 
  return (
    <>
        <div className={styles.content}>
            <div className={styles.rowTitle}>
                <h3 className={'titleGreen '}>
                    {step===1?"C’est la première fois que vous vendez un livre ici.":"Pièce d'identité"}
                </h3>
                <p >La législation nous impose de renseigner les informations suivantes : </p>
            </div>
        {step === 1 ?
        <>
        <form 
            className={'container ' + styles.form}
            id='form-step1'
            onSubmit={e => {
                e.preventDefault();
                setStep(2)
            }}
            >
    
            <div className={'form-row ' + styles.rowStep1}>
                {/* <div className={'col ' + styles.col}> */}
                <div className={"form-group col-md-2 " + styles.inputBox}>
                    <label >Civilité</label>
                    <select className="form-control"
                        value={ form.appellation || ''}
                        onChange={e => setForm({...form, appellation: e.target.value}) }
                        required
                    >
                        <option value='' disabled>-</option>
                        <option value='M'>M</option>
                        <option value='Mme'>Mme</option>
                        <option value='Mlle'>Mlle</option>
                    </select>

                </div>
                <div className={"form-group col-md-3 " + styles.inputBox }>
                    <label >Prénom</label>
                    <input type="text" className="form-control" 
                        placeholder="Votre prénom"
                        onChange={e => setForm({...form, firstName: e.target.value}) }
                        value={form.firstName || ''}
                        required
                    />
                </div>
                <div className={"form-group col-md-5 " + styles.inputBox}>
                    <label >Nom</label>
                    <input type="text" className="form-control" placeholder="Votre nom"
                        onChange={e => setForm({...form, lastName: e.target.value}) }
                        value={form.lastName || ''}
                        required
                    />
                </div>
            </div>
            <div className={'form-row ' + styles.rowStep1}>
                <div className={"form-group col-md-10 " + styles.inputBox}>
                    <label >Adresse</label>
                    <input type="text" className="form-control" placeholder="N° et voie"
                        onChange={e => setForm({...form, address: e.target.value}) }
                        value={form.address || ''}
                        required
                    />
                </div>
               
            </div>
            <div className={'form-row ' + styles.rowStep1}>
            <div className={"form-group col-md-2 " + styles.inputBox}>
                    <label >Code Postal</label>
                    <input type="text" className="form-control" placeholder="123456"
                        onChange={e => setForm({...form, postcode: e.target.value}) }
                        value={form.postcode || ''}
                        required
                    />
                </div>
                <div className={"form-group col-md-6 " + styles.inputBox}>
                    <label >Ville</label>
                    <input type="text" className="form-control" placeholder="Votre ville"
                        onChange={e => setForm({...form, city: e.target.value}) }
                        value={form.city || ''}
                        required
                    />
                </div>
                <div className={"form-group col-md-2 " + styles.inputBox}>
                    <label >Pays</label>
                    <input type="text" className="form-control" placeholder="Pays"
                        onChange={e => setForm({...form, country: e.target.value}) }
                        value={form.country || ''}
                        required
                    />
                </div>
            </div>
            <div className={'form-row ' + styles.rowStep1}>
                <div className={"form-group col-md-10 " + styles.inputBox}>
                    <label >Adresse e-mail</label>
                    <input type="email" className="form-control" placeholder="votre.adresse@email.fr"
                        pattern="[a-zA-Z0-9.-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}"
                        onChange={e => {setForm({...form, email: e.target.value})} }
                        value={form.email || ''}
                        required
                    />
                    {/* <small id="emailHelp" className="form-text text-muted">exemple : martin@mail.com</small> */}
                </div>
            </div>
            </form>
            <div className={'row ' + styles.rowBottom}>
                <button 
                    className='newBtn grayDecitre white'
                    onClick={ () => navigationDispatch({type:'goCheckout'})}
                >
                    <img 
                        className={'m10-right'}
                        src={iconReturnWhite} alt='bouton retour'></img>
                    retour
                </button>
                <button 
                    className='newBtn greenDecitre white'
                    form='form-step1'
                    type='submit'
                >
                <img 
                    className={'m10-right'}
                    src={pictoAccepted} alt='picto accepter'></img>
                    Suivant
                </button>
            </div>
        </>

        :step === 2 ?
            <>
            <form 
                className={'container ' + styles.form}
                id='form-step2'
                onSubmit={e => {
                    e.preventDefault();
                    setQuery(form)
                }}>

                <div className={'row ' + styles.rowStep2}>
                    {/* <div className={'col ' + styles.col}> */}
                        <div className={"form-group col-md-10 " + styles.inputBox}>
                            <label>Type</label>
                            <select className="form-control"
                                value={ form.personalIdType || ''}
                                onChange={e => setForm({...form, personalIdType: e.target.value})} 
                                required   
                            >
                                <option value='' disabled>-- Sélectionner un type --</option>
                                <option value='Passport'>Passeport</option>
                                <option value="idCard">Carte d'identité</option>
                            </select>
                        </div>
                        <div className={"form-group col-md-10 " + styles.inputBox}>
                            <label>Numéro de la pièce d'identité</label>
                            <input type="text" className="form-control"
                                onChange={e => setForm({...form, personalIdNumber: e.target.value})} 
                                value={form.personalIdNumber || ''}
                                required
                            />
                        </div>
                        <div className={"form-group col-md-5 " + styles.inputBox}>
                            <label >Délivrée le</label>
                            <DatePicker
                                className={"form-control "}
                                locale='fr'
                                dateFormat="dd/MM/yyyy"
                                onChange={date => setForm({...form, personalIdDeliveryDate: date})}
                                selected={form.personalIdDeliveryDate || ''}
                                required
                                
                            />
                        </div>
                        <div className={"form-group col-md-5 " + styles.inputBox}>
                            <label >Délivrée par</label>
                            <input type="text" className="form-control"
                                onChange={e => setForm({...form, personalIdDeliveryFrom: e.target.value})} 
                                value={form.personalIdDeliveryFrom || ''}
                                required
                            />
                        </div>
                        <div className={"form-group col-md-10 " + styles.checkBox}>
                            <input type="checkbox" 
                                 required/>
                            <label className={styles.honorText}>J’atteste sur l’honneur que les informations renseignées ci-dessus sont certifiées exactes </label>
                        </div>
                    {/* </div> */}
                </div>
            </form>
                <div className={'row ' + styles.rowBottom}>
                    <button 
                        // className='btn btn-s btn-red'
                        className='newBtn grayDecitre white'
                        type='button'
                        onClick={ () => {setStep(1); setError()}}
                    >
                        <img
                            className={'m10-right'}
                            src={iconReturnWhite} alt='bouton retour'></img>
                        Retour
                    </button>
                    <button 
                        // className='btn btn-s btn-blue'
                        className='newBtn greenDecitre white'
                        form='form-step2'
                        type='submit'
                    >
                        <img
                            className={'m10-right'}
                            src={pictoAccepted} alt='picto accepter'></img>
                        Suivant
                    </button>
                </div>
                {error?
                <span className={"alert alert-danger " + styles.error} role="alert">
                    {error}
                </span>
            :null}
            </>
            :null}
        </div>
    </>
  );
}

export default CustomerToBeUpdate;                   
                    
                    
                    
                    
                    