import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'
import pictoAccepted from '../../assets/icon-accepter.svg'
import iconReturn from '../../assets/icon-retour-blanc.svg'

import styles from './CustomerLogin.module.css';
import { axiosConfig } from '../../utils/axiosConfig';
// var axiosConfig = require('../../utils/axiosConfig')
function CustomerLogin() {
    const { navigationDispatch } = React.useContext(NavigationContext);
    const { authState, authDispatch } = React.useContext(AuthContext);
    const [ error, setError ] = React.useState('');

    const [ customer, setCustomer ] = React.useState({
        username: '',
        password: ''
    });
    const [ query, setQuery ] = React.useState('');

    React.useEffect(() => {
        const loginCustomer = () =>
        axiosConfig.post(`${process.env.REACT_APP_API_URL}/apiFront/customer/login`,
         query
        )
        .then(res=>{
                if (res.data.status && !res.data.toBeUpdated) {
                    authDispatch({type:'customerAuth', payload: res.data.user});
                    navigationDispatch({type:'customerLoggedIn'});
                }else if (res.data.status && res.data.toBeUpdated) {
                    authDispatch({type:'customerAuth', payload: res.data.user});
                    navigationDispatch({type:'customerTobeUpdate'});
                }else{
                      setError('Identifiant ou mot de passe invalide');
                }
            })
        .catch(error => {
            setQuery('')
            console.log(error);
        });
        if (query !== '') {
            setError('')
            loginCustomer();
        }
    }, [query, navigationDispatch, authDispatch, authState])
    
  return (
    <>  
        <form
            className={styles.loginForm}
            onSubmit={e => {
                e.preventDefault();
                setQuery(customer)
        }}>
            <div className={styles.flexCol}>
       
                <div className={"form-group "+styles.inputBox}>
                    <label >Adresse e-mail</label>
                    <input type="email" className="form-control" 
                        placeholder="Enter email"
                        onChange={e => setCustomer({...customer, username: e.target.value.toLowerCase()}) }
                        value={customer.username || ''}
                        required
                        />
                </div>
                <div className={"form-group "+styles.inputBox}>
                    <label >Mot de passe </label>
                    <input type="password" className="form-control" 
                    placeholder="Password"
                    onChange={e => setCustomer({...customer, password: e.target.value}) }
                    value={customer.password || ''}
                    required
                    />
                    <div 
                        className={styles.forgotLink}
                        onClick={()=>navigationDispatch({type:'customerForgotPassword'})} >
                            Mot de passe oublié ?
                    </div>
                </div>
                {error !== '' ?
                <span className={"alert alert-danger " + styles.error} role="alert">
                    {error}
                </span>
            :null}
            </div>
                <div className={styles.btnBox}>
                    <div className={styles.returnBox}>
                        <button 
                            // className={'btn btn-s btn-blue ' + styles.btn}
                            className={'newBtn white grayDecitre '}
                            onClick={ () => navigationDispatch({type:'goCheckout'})}
                        >
                            <img 
                                className={'m10-right'}
                                src={iconReturn} alt='icone retour'></img>
                            retour
                        </button>
                    </div>
                    <button 
                        type="submit" 
                        className={'newBtn white greenDecitre '}
                    >
                        <img 
                            className={'m10-right'}
                            src={pictoAccepted} alt='icone accepter'></img>
                        se connecter
                    </button>
                </div>
        </form>
            
    </>
    );
}

export default CustomerLogin;                   
                    
                    
                    
                    
                    