import React from 'react';
import styles from './CheckoutPage.module.css'

import { NavigationContext } from '../../store/NavigationContext'

import CartChekout from '../../components/CartCheckout/CartCheckout'
import CustomerLogin from '../../components/CustomerLogin/CustomerLogin'
import CustomerSignup from '../../components/CustomerSignup/CustomerSignup'
import CustomerTobeUpdate from '../../components/CustomerTobeUpdate/CustomerToBeUpdate'

import UserLogin from '../../components/UserLogin/UserLogin'
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword'

import TerminateSale from '../../components/TerminateSale/TerminateSale'
import GiftCard from '../../components/GiftCard/GiftCard'
import pictoAccepted from '../../assets/icon-accepter.svg'
import pictoPlus from '../../assets/icon-plus.svg'
import iconReturn from '../../assets/icon-retour-blanc.svg'

function CheckoutPage() {
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext);
    // console.log("navigationState",navigationState)
    return (
        <>
            {navigationState.displayCheckoutPage ?

                <div className={"container-fluid pageContainer " + styles.page}>
                    <div className={'row ' + styles.content}>
                        <div className={'col ' + styles.loginCol}>
                            <h2 className={styles.title}>Pour finaliser ma vente, <br></br> je m'identifie</h2>
                            <button 
                                // className={'btn btn-s btn-red ' + styles.btn}
                                className={'newBtn white greenDecitre '}
                                onClick={ () => navigationDispatch({type:'customerLogin'})}
                            >
                                <img 
                                    className={'m10-right'}
                                    src={pictoAccepted} alt='icone accepter'></img>
                                Je m'identifie</button>
                            <button 
                                // className={'btn btn-s btn-blue ' + styles.btn}
                                className={'newBtn white grayDecitre '}
                                onClick={ () => navigationDispatch({type:'customerSignup'})}
                            >
                                <img 
                                    className={'m10-right'}
                                    src={pictoPlus} alt='icone plus'></img>
                               Je crée un compte
                            </button>
                            <div className={styles.returnBox}>
                                <button 
                                    // className={'btn btn-s btn-blue ' + styles.btn}
                                    className={'newBtn white grayDecitre '}
                                    onClick={()=>{navigationDispatch({type:'termsOfUseAccept'})}}
                                >
                                    <img 
                                        className={'m10-right'}
                                        src={iconReturn} alt='icone retour'></img>
                                    retour
                                </button>
                            </div>
                        </div>
                
                        <div className={'col ' + styles.col}>
                            <CartChekout/>
                        </div>

                    </div>
                </div>

            :navigationState.displayCustomerLogin ?
            
            <div className={"container-fluid pageContainer " + styles.page}>
            {/* <div className={'container ' + styles.h100}> */}
                <div className={'row ' + styles.content}>
                    <div className={'col ' + styles.loginCol} >
                        <h2 className={styles.title}>
                            Pour finaliser ma vente, <br></br> je m'identifie
                        </h2>
                        <CustomerLogin/>
                    </div>
                    <div className={'col ' + styles.col}>
                        <CartChekout/>
                    </div>
                </div>
            </div>
            :navigationState.displayCustomerForgotPassword ?
            // <div className={'container '}>
            <div className={"container-fluid pageContainer " + styles.page}>
                <div className={'row ' + styles.content}>
                    <div className={'col ' + styles.loginCol} >
                        <ForgotPassword/>
                    </div>
                    <div className={'col ' + styles.col}>
                        <CartChekout/>
                    </div>
                </div>
            </div>
            :navigationState.displayCustomerSignup ?
                        
            <div className={"container-fluid pageContainer " + styles.page}>        
                <CustomerSignup/>
            </div>
            :navigationState.displayCustomerTobeUpdate ?
                                    
            <div className={"container-fluid pageContainer " + styles.page}>        
                <CustomerTobeUpdate/>
            </div>

            :navigationState.displayUserLogin ?
                                    
            // <div className={'container ' + styles.container}>  
            <div className={"container-fluid pageContainer " + styles.page}>
            <div className={'row ' + styles.content}>
                    <div className={'col ' + styles.loginCol} >
                        <UserLogin/>
                    </div>
                    <div className={'col ' + styles.col}>
                        <CartChekout/>
                    </div>
                </div>       
  
            </div>

            :navigationState.displayCartValidation ?
                                                
            // <div className={'container '}>
            <div className={"container-fluid pageContainer " + styles.page}>
                    <div className={'row ' + styles.content}>
                        <div className={'col ' + styles.loginCol} >
                            <button 
                                className={'newBtn white grayDecitre '}
                                onClick={ () => navigationDispatch({type:'termsOfUseAccept'})}
                            >
                                <img 
                                    className={'m10-right'}
                                    src={pictoPlus} alt='icone plus'></img>
                               J'ajoute un livre
                            </button>
                            <TerminateSale/>

                        </div>
                        <div className={'col ' + styles.col}>
                            <CartChekout/>
                        </div>
                    </div>
                </div>
            
            :navigationState.displayGiftCard ?
                                                
            <div className={"container-fluid pageContainer " + styles.page}>
                        <div className={'col ' + styles.giftCardBox} >
                            <GiftCard/>
                        </div>
                </div>

            :null}
        </>
    );
  }
  
  export default CheckoutPage;