import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { BookContext } from '../../store/BookContext'
import { AuthContext } from '../../store/AuthContext'
import ScanInput from '../../components/ScanInput/ScanInput'
import BookChoice from '../../components/BookChoice/BookChoice'
import styles from './ScanPage.module.css'

import pictoAccepted from '../../assets/icon-accepter.svg'

function ScanPage() {
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext)
    const { bookState } = React.useContext(BookContext)
    const { authState } = React.useContext(AuthContext)
    const needCheckOut = bookState.cart.filter(function(book){ return book.userChoice === "sold" }).length;
    
  return (
    <>
        {navigationState.displayScanPage ?
            <div className={"container-fluid pageContainer " + styles.page}>  
            {/* <div className={"row " + styles.row }> */}
                <div className={styles.row }>
                    
                    {!bookState.book ?
                       <div className={styles.w75 + " " + styles.customHeight}>
                            <div className={styles.paddingLeft}>
                                {bookState.lastAction === 'SELL' ?
                                    <div>
                                        <h4 className={styles.titleRefurb}>Le livre {bookState.cart[bookState.cart.length - 1].title} a été ajouté à votre reprise</h4>
                                        <p className={styles.helpText}>N’hésitez pas à demander de l’aide à un vendeur si besoin.</p>
                                    </div>
                                    :bookState.lastAction === 'GIVE' ?
                                    <div className={ styles.rowGiven }>
                                        {authState.store.givenSentence?<h4 className={styles.titleGivenSentence}>{authState.store.givenSentence}</h4>:null}
                                        {authState.store.givenSentence2?<h4 className={styles.titleGivenSentence}>{authState.store.givenSentence2}</h4>:null}
                                        <p className={styles.helpText}>N’hésitez pas à demander de l’aide à un vendeur si besoin.</p>
                                    </div>
                                :null}
                                {!bookState.lastAction ?
                                    <h2 className={styles.title}>Je scanne le code-barres du livre</h2>
                                :
                                    <h2 className={styles.title}>Je scanne le code-barres d'un autre livre</h2>
                                }
                                <ScanInput/>
                                <div className={styles.btnBox}>
                                    {authState.user !== null && authState.customer !== null ?
                                        <button 
                                            // className={'btn btn-s btn-blue'}
                                            className={'newBtn greenDecitre white'}
                                            onClick={ () => navigationDispatch({type:'userLoggedIn'})}
                                        >
                                            <img 
                                                className={'m10-right'}
                                                alt='picto accepté' src={pictoAccepted}/>
                                            J'ai terminé</button>
                                        :bookState.cart.length > 0 && needCheckOut === 0 ?
                                            <button 
                                                // className={'btn btn-s btn-blue'}
                                                className={'newBtn greenDecitre white'}
                                                onClick={ () => navigationDispatch({type:'landingGive'})}
                                            >
                                                <img 
                                                    className={'m10-right'}
                                                    alt='picto accepté' src={pictoAccepted}/>
                                                J'ai terminé</button>
                                        :bookState.cart.length > 0 && (!bookState.book || bookState.book.status === 'rejected') && needCheckOut > 0 ?
                                            <button 
                                                // className={'btn btn-s btn-blue'}
                                                className={'newBtn greenDecitre white'}
                                                onClick={ () => navigationDispatch({type:'goCheckout'})}
                                            >
                                                <img 
                                                    className={'m10-right'}
                                                    alt='picto accepté' src={pictoAccepted}/>
                                                J'ai terminé</button>
                                    : null
                                    }
                                </div>
                            </div>
                        </div>
                    :
                        <BookChoice/>
                    }                 
                </div>
            </div>
        :null}
    </>
  );
}

export default ScanPage;
