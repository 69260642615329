import React from 'react';

const NavigationContext = React.createContext();
const localState = JSON.parse(localStorage.getItem('navigationState'));

const initialState = {
    refurbId: null,
    // isLoading: false,
    displayStoreLogin: false,
    displayLanding: true,
    displayLandingGive: false,
    displayLandingSell: false,
    displayScanPage: false,
    displayCheckoutPage: false,
    displayCustomerLogin: false,
    displayCustomerSignup: false,
    displayCustomerTobeUpdate: false,
    displayCustomerForgotPassword: false,
    displayUserLogin: false,
    displayCartValidation: false,
    displayGiftCard: false,
    displayIntroPage: {status: false, page:1},
}
function navigationReducer(state, action){
    switch (action.type) {
        case 'reset': {
            localStorage.removeItem("navigationState")
            return {
                ...initialState,
                displayStoreLogin: true
            }
        }
        // case 'isLoading': {
        //     return {
        //         ...state,
        //         isLoading: true
        //     }
        // }
        // case 'endLoading': {
        //     return {
        //         ...state,
        //         isLoading: false
        //     }
        // }
        case 'displayStoreLogin': {
            return {
                ...initialState,
                displayLanding: true,
                displayIntroPage: action,

                displayHomeScreen: false

            }
        }
        case 'displayIntroPage': {
            return {
                ...initialState,
                displayIntroPage: action,
                displayLanding: false
            }
        }
        case 'displayHomeScreen': {
            return {
                ...initialState,
                displayHomeScreen: true,
                displayLanding: false
            }
        }
        case 'storeLoggedIn': {
            return {
                ...initialState,
                displayLanding: true,
                // displayIntroPage: true,
            }
        }
        case 'landingGive': {
            return {
                ...initialState,
                displayLandingGive: true,
                displayLanding: false
            }
        }
        case 'landingSell': {
            return {
                ...initialState,
                displayLandingSell: true,
                displayLanding: false
            }
        }
        case 'termsOfUseAccept': {
            return {
                ...initialState,
                displayScanPage: true,
                displayLanding: false

            }
        }
        case 'goCheckout': {
            return {
                ...initialState,
                displayCheckoutPage: true,
                displayLanding: false

            }
        }
        case 'customerLogin': {
            return {
                ...initialState,
                displayCustomerLogin: true,
                displayLanding: false
            }
        }
        case 'customerTobeUpdate': {
            return {
                ...initialState,
                displayCustomerTobeUpdate: true,
                displayLanding: false
            }
        }
        case 'customerForgotPassword': {
            return {
                ...initialState,
                displayCustomerForgotPassword: true,
                displayLanding: false
            }
        }
        case 'customerSignup': {
            return {
                ...initialState,
                displayCustomerSignup: true,
                displayLanding: false
            }
        }
        case 'customerLoggedIn': {
            return {
                ...initialState,
                displayUserLogin: true,
                displayLanding: false
            }
        }
        case 'userLoggedIn': {
            return {
                ...initialState,
                displayCartValidation: true,
                displayLanding: false
            }
        }
        case 'cartValidated': {
            const newRefurbId = action.payload._id;
            return {
                ...initialState,
                displayGiftCard: true,
                refurbId: newRefurbId,
                displayLanding: false
            }
        }
        default: {
            return state;
        }
    }
}

function NavigationProvider({children}) {
    const [navigationState, navigationDispatch] = React.useReducer(navigationReducer, localState || initialState)

    React.useEffect(() => {
        localStorage.setItem('navigationState', JSON.stringify(navigationState))
    }, [navigationState])

    return (
      <NavigationContext.Provider value={{navigationState, navigationDispatch}}>
          {children}
      </NavigationContext.Provider>
    )
}

export { NavigationProvider, NavigationContext }