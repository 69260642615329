import React from 'react';

const AuthContext = React.createContext();
const localState = JSON.parse(localStorage.getItem('authState'));

const initialState = {
    store: {givenSentence: null, givenSentence2: null},
    customer: null,
    user: null 
}
function authReducer(state, action){
    switch (action.type) {
        case 'reset': {
            localStorage.removeItem("authState")
            return initialState
        }
        case 'endOfSale': {
            return { 
                ...state,
                customer: null,
                user: null
            }
        }
        case 'storeAuth': {
            return {
                ...state,
                store: action.payload
            }
        }
        case 'customerAuth': {
            return {
                ...state,
                customer: action.payload
            }
        }
        case 'userAuth': {
            return {
                ...state,
                user: action.payload
            }
        } 
        default: {
            return state;
        }
    }
}

function AuthProvider({children}) {
    const [authState, authDispatch] = React.useReducer(authReducer, localState || initialState)

    React.useEffect(() => {
        localStorage.setItem('authState', JSON.stringify(authState))
    }, [authState])
    
    return (
            <AuthContext.Provider value={{authState, authDispatch}}>
                {children}
            </AuthContext.Provider>
    )
}

export { AuthProvider, AuthContext }