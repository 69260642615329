import React from 'react';
import { BookContext } from '../../store/BookContext'

import styles from './CartCheckout.module.css';
import pictoGreenBooks from '../../assets/picto-livres-vert.svg'
import iconRedTrash from '../../assets/icon-poubelle-rouge.svg'
function CartCheckout() {
  const { bookState, bookDispatch } = React.useContext(BookContext);
  let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
  const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0);
  return (
    <>
      <div className={styles.container}>
          <div className={styles.cartBlockTitle}>
            <img className={styles.bookLogo} src={pictoGreenBooks} alt='logo'/>
            <h3 className={styles.cartTitle}>Livres repris</h3>
          </div>

          <div className={styles.bookBlock}>
          {filteredCart.map((book, index) => {
              return <div className={styles.bookItem} key={book.id}>
                        <span className={styles.bookTitle}>{book.title}</span>
                        <span className={styles.priceBox}>{book.price.toFixed(2).replace('.', ',')}<sup>€</sup>
                          <img  className={styles.trash} src={iconRedTrash} alt='poubelle'
                            onClick={() => bookDispatch({ type:'deleteBook', payload:book.id })}
                          ></img>
                        </span>
                      </div>
          })}
          </div>
          <div className={styles.cartBlockTotal}>
            <h2 className={styles.cartTotal}>Total*</h2>
            <span className={styles.cartTotal}>{totalPrice.toFixed(2).toString().replace('.', ',')}<sup>€</sup></span>
          </div>
      </div>
      <div>* Sous réserve de validation du  libraire Decitre</div>
    </>
  );
}

export default CartCheckout;
