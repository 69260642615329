import React from 'react';
import { BookContext } from '../../store/BookContext'
import { AuthContext} from '../../store/AuthContext'
import styles from './BookChoice.module.css'
import pictoEuroWhite from  '../../assets/icon-euro-blanc.svg'
import pictoGreenHeart from  '../../assets/icon-coeur-vert.svg'
import pictoCancel from '../../assets/x-circle.svg'

function ScanInput() {
    const { bookState, bookDispatch } = React.useContext(BookContext);
    const { authState } = React.useContext(AuthContext);
    const [isExistBook, setIsExistBook] = React.useState(false)
    
    React.useEffect(()=>{
        if (bookState.book && bookState.cart.filter(e=>e.userChoice==='sold').some(e => e.isbn13 === bookState.book.isbn13 )) { 
            // console.log('Is exist')
            setIsExistBook(true)
        }
    },[bookState.book, bookState.cart])


  return (
        <>
            <div className={'row ' + styles.bookRow}>
                <div className={'col ' + styles.bookInfo} >
                    <img
                        className={styles.imageResponsive}
                        src={bookState.book.image}
                        alt={bookState.book.title}/>
                </div>
                <div className={'col '}>
                    <h2 className={styles.titleBook}>{bookState.book.title}</h2>
                    {bookState.book.authors ?
                        <p className={styles.authors}>
                            {bookState.book.authors.map((author, index) => {
                                return index + 1 < bookState.book.authors.length ?
                                    <span key={index}>{author} et </span>
                                :
                                    <span key={index}>{author}</span>
                            })}
                        </p>
                    :null}
                    <div className={styles.detailsList}>
                        <div>
                        <div className={styles.detailsTitle}>ISBN</div>
                        <span>{bookState.book.isbn13}</span>
                        </div>
                        <div>
                        <div className={styles.detailsTitle}>Editeur</div>
                        <span>{bookState.book.publisher}</span>
                        </div>
                        <div>
                        <div className={styles.detailsTitle}>Edition</div>
                        <span>{bookState.book.edition_year} {bookState.book.edition}</span>
                        </div>
                    </div>
                </div>
            </div>

  
            <div className={'greenDecitre ' + styles.container }>
                    {bookState.book.status === 'accepted' && !isExistBook ?
                        <h2 className={styles.title + " white" }>{authState.store.acceptedSentence} <span className={styles.price}>{bookState.book.price.toFixed(2).replace('.', ',')}<sup>€</sup></span></h2>
                    :
                    bookState.book.status === 'accepted' && isExistBook ?
                        <h3 className={styles.title + " white" }>Ce livre est déjà dans votre panier.</h3>
                    :

                        <h3 className={styles.title + " white" }>{authState.store.rejectedSentence}</h3>
                    }
                <div className={ styles.buttonRow }>
              
                    <button
                        // className={'btn btn-s btn-blue'}
                        className={'newBtn grayDecitre white'}

                        onClick={ () => bookDispatch({type:'keepBook'})}
                    >
                        <img 
                            className={'m10-right'}
                            alt='picto croix' src={pictoCancel}></img>
                        Je le garde
                    </button>
                    {bookState.book.status === 'accepted' && !isExistBook?
                        <button
                            className={'newBtn grayDarkDecitre white'}
                            // className={'btn btn-s btn-orange'}
                            onClick={ () => bookDispatch({type:'sellBook'})}
                        >
                            <img 
                                className={'m10-right'}
                                alt='picto euro' src={pictoEuroWhite}></img>
                            Je l'échange contre bon d'achat
                        </button>
                    :null}
                    <button
                        // className={'btn btn-s btn-red'}
                        className={'newBtn mintDecitre grayDarkDecitreText'}
                        onClick={ () => bookDispatch({type:'giveBook'})}
                        
                    >
                       <img 
                            className={'m10-right'}
                            alt='picto coeur' src={pictoGreenHeart}></img>
                        Je le donne</button>
                </div>
            </div>

        </>
  );
}

export default ScanInput;
