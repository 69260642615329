import React from 'react';
// import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'
import { BookContext } from '../../store/BookContext'
import { NavigationContext } from '../../store/NavigationContext'
import { axiosConfig } from '../../utils/axiosConfig';
import styles from './GiftCard.module.css';

function GiftCard() {
    const { authState } = React.useContext(AuthContext);
    const { bookState } = React.useContext(BookContext);
    const { navigationState } = React.useContext(NavigationContext);

    const [ error, setError ] = React.useState('');
    const [ etiquette, setEtiquette ] = React.useState(null);

    React.useEffect(() => { 
        const CreateGiftCard = () => {
            axiosConfig.post('apiFront/giftCard', {
                "refurbId": navigationState.refurbId,
                "partner": authState.store.code
            },
                {responseType: "blob"}
            )
            .then(response=>{
                if (!response) {
                    setError('Une erreur est survenue lors de la demande d\'impression de la carte')
                }else{
                    var file = new Blob([response.data], {type: 'application/pdf'});
                    setEtiquette(URL.createObjectURL(file));
                }
            })
                
        }
        if(etiquette === null && authState.user && authState.customer && authState.store){
            CreateGiftCard()
        }
        
    }, [etiquette, authState.customer, authState.store, authState.user, bookState.cart, navigationState.refurbId])

  return (
        <>
            {etiquette === null ?
                <div className={styles.endSentence}>
                    <h2 className={styles.title}>
                        Votre vente est terminée
                    </h2>
                    <h3>La génération de votre bon d'achat est en cours</h3>
                    {error !== '' ?
                        <span className={"alert alert-danger " + styles.error} role="alert">
                            {error}
                        </span>
                    :null}
                </div>
            :
                <div  className={styles.objectPdf}>
                    {/* <h2 className={styles.title}>Votre vente est terminée</h2> */}
                    {/* <object width="100%" height="100%" data={ process.env.REACT_APP_API_URL + card } type="application/pdf">   </object> */}
                    
                    <object width="100%" height="100%" data={ etiquette } type="application/pdf">   </object>
                  
                    {/* <h3>Une carte cadeaux d'un montant de  { totalPrice } € est en cours d'impression</h3>
                    <a href={ apiUrl + card }>Votre carte Cadeau</a>
                    <button 
                    className={'btn btn-s btn-blue ' + styles.btn}
                    onClick={ () => {
                        authDispatch({ type:'endOfSale' });
                        navigationDispatch({ type:'storeLoggedIn' });
                        bookDispatch({ type:'reset' })  
                        }}>Terminer</button> */}
                </div>
            }
        
        </>
    );
}

export default GiftCard;                   
                    
                    
                    
                    
                    