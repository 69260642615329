import React from 'react';
import { BookContext } from '../../store/BookContext'
import { AuthContext } from '../../store/AuthContext'
import { NavigationContext } from '../../store/NavigationContext'

import styles from './Cart.module.css';
import pictoBooks from '../../assets/picto-livres-mint.svg'
import pictoEuros from '../../assets/picto-euro-mint.svg'

function Cart() {
  const { bookState } = React.useContext(BookContext)
  const { authState } = React.useContext(AuthContext)
  const { navigationState } = React.useContext(NavigationContext)

  let filteredCart = bookState.cart.filter(book => book.userChoice === 'sold')
  const totalPrice = filteredCart.reduce((count, curItem) => count + curItem.price, 0)

  return (
    <div className={"container-fluid " + styles.container}>
        <div className={"row " + styles.row}>

        {authState.store !== null ?

          <div className={styles.storeName}>{authState.store.nom}</div>

        :<div></div>}

        {/* {filteredCart.length > 0 ? */}
        {(navigationState.displayScanPage || navigationState.displayCustomerLogin) ?
        <div className={styles.cart}>
            <div className={styles.cartCount}>
              <img className={styles.cartBarLogo} src={pictoBooks} alt='picto livres'/>
              <span className={styles.cartBarText}>{filteredCart.length} </span>
          </div>
          <div className={styles.cartValue}> 
            <img className={styles.cartBarLogo} src={pictoEuros} alt='picto euros'/>
            <span className={styles.cartBarText}>{totalPrice.toFixed(2).toString().replace('.', ',')}<sup>€</sup></span>
          </div>
    
        </div>

          :null}
        </div>
    </div>
  );
}

export default Cart;
