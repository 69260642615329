import React, { useState } from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import LogoSellYourBooks from '../../assets/logo-revendezvoslivres.svg'
import styles from './IntroPage.module.css';
import pictoMouse from '../../assets/picto-souris.svg'
import iconLivres from '../../assets/icon-livres.svg'
import iconHeart from '../../assets/icon-coeur.svg'
import iconRecycle from '../../assets/icon-recyclage.svg'
import iconLinkGreen from '../../assets/icon-lien-vert.svg'
import iconThumb from '../../assets/icon-pouce-vert.svg'
import iconReturn from '../../assets/icon-retour-blanc.svg'
import iconRightArrow from '../../assets/arrow-right-circle.svg'

function IntroPage() {
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext)
    // console.log("Display Intro",navigationState)
    const [step, setStep] = useState(navigationState.displayIntroPage.page)
return (
    <div className={'pageContainer '+ styles.container}>

    {step===1?
        <>
            <div className={styles.titleBox}>
                <img alt='logo revente livres' src={LogoSellYourBooks}></img>
                <h1 className={styles.mainTitle}>Vendez ou donnez<br></br> vos livres</h1>
            </div>
            <div className={styles.globalBtnBox}>
                <div onClick={()=>{navigationDispatch({type:'displayStoreLogin'})}} className={styles.mainBtnBox}>
                    <div >Commencez<br></br> en cliquant ici
                        <img alt='picto souris' src={pictoMouse}/>
                    </div>
                </div>
                <div className={styles.secondaryBtnBox}>
                    <div onClick={()=>setStep(2)}>Comment ça marche</div>
                    <div onClick={()=>setStep(3)}>Les livres acceptés</div>
                </div>
            </div>
            <div className={styles.logoTextContainer}>
                <div className={styles.logoTextBox}>
                    <img alt='picto livres' src={iconLivres} className={styles.icon}/>

                    <div className={styles.pictoText}>
                        <p>Decitre reprend vos livres en partenariat avec Ammareal afin de redonner une seconde vie à vos livres.</p>
                    </div>
                </div>
                <div className={styles.logoTextBox}>
                    <img alt='picto livres' src={iconHeart} className={styles.icon}/>

                    <div className={styles.pictoText}>
                        <p>5% du produit des ventes de livres par Ammareal sont versés à des associations de lutte contre l’illettrisme.</p>
                    </div>
                </div>
                <div className={styles.logoTextBox}>
                    <img alt='picto livres' src={iconRecycle} className={styles.icon}/>

                    <div className={styles.pictoText}>
                        <p>Les livres qui ne sont pas vendus sont donnés à des associations. Ceux qui ne sont pas donnés sont recyclés.</p>
                    </div>
                </div>
            </div>
        </>
    :step===2?
    <>
        <div className={styles.headerIntro}>
            <img alt='logo revente livres' src={LogoSellYourBooks} width={75}></img>
            <h1>Comment ça marche</h1>
        </div>
        <div className={styles.content}>
            <div>
                <p className={styles.mB34}>Nous rachetons vos livres à un prix spécifique à chaque livre. Le montant est versé sur votre carte de fidélité Decitre ou fait l’objet d’un bon d’achat valable 2 mois.
                <br></br>
                Vous pouvez aussi faire le choix de simplement les donner. </p>
                <p className={styles.mB34}>Les livres qui ne seront pas revendus seront donnés. Ceux qui ne seront pas donnés seront recyclés.</p>
                <p className={styles.mB34}>Plus d’informations sur <a href='https://www.ammareal.fr' target='_blank' rel="noopener noreferrer">ammareal.fr</a></p>
                <p>
                    <span  onClick={()=>{setStep(3)}} className={styles.link}>Voir la liste des livres acceptés
                        <img src={iconLinkGreen} alt='icon lien'></img>
                    </span>
                </p>
            </div>
            <div className={styles.btnBox}>
            <button 
                className={'newBtn grayDecitre white' }
                onClick={()=>{setStep(1)}}

            >
                <img 
                    className={'m10-right '}
                    src={iconReturn} alt='retour'></img>
                {/* <i className="far fa-arrow-alt-circle-left"></i>  */}
                <span> </span> retour
            </button>
            <button 
                className={'newBtn greenDecitre white '}
                onClick={()=>{navigationDispatch({type:"displayStoreLogin"})}}
            >
                Commencer<span> </span>
                <img 
                    className={'m10-left '}
                    src={iconRightArrow} alt='fleche droite'>
                </img>
                {/* <i className="far fa-arrow-alt-circle-right"></i>  */}
            </button>
            </div>
        </div>
    </>
    :step===3?
    <>
        <div className={styles.headerIntro}>
            <img alt='logo revente livres' src={LogoSellYourBooks} width={75}></img>
            <h1>Listes des livres acceptés</h1>
        </div>
        <div className={styles.content}>
            <div>
                <div>
                    <div className={styles.iconSubtitleBox + " " + styles.noMarginTop} >
                        <img src={iconThumb} alt='icone pouce'></img>
                        <p>Nous reprenons</p>
                    </div>
                    Livres de poche, grand format, bandes dessinées livres d’art, manuels scolaires et cahiers de vacances (non remplis), ouvrages techniques et universitaires.
                    <br></br>
                    Nous reprenons les livres quelle que soit la langue.
                    <br></br>
                    Nous reprenons uniquement les livres en très bon état.
                    <br></br>
                    Les livres doivent avoir un code-barres.
                </div>
                <div>
                    <div className={styles.iconSubtitleBox } >
                        <i className={styles.red + ' fas fa-ban'}></i>
                        <p className={styles.red} >Nous ne reprenons pas</p>
                    </div>
                    Les revues, les encyclopédies, les maisons d’éditions non vendues en librairie.
                    <br></br>
                    Les livres abîmés (reliures cassées, livres tâchés, livres annotés…).
                    <br></br>
                    Nous ne reprenons pas les livres sans code-barres.
                </div>
            </div>  
            <div className={styles.btnBox}>
            <button 
                className={'newBtn grayDecitre white' }
                onClick={()=>{setStep(1)}}

            >   
                <img 
                    className={'m10-right '}
                    src={iconReturn} alt='retour'>
                </img>
                {/* <i className="far fa-arrow-alt-circle-left"></i>  */}
                <span> </span> retour
            </button>
            <button 
                className={'newBtn greenDecitre white'}
                onClick={()=>{navigationDispatch({type:"displayStoreLogin"})}}
            >
                Commencer<span> </span>
                <img 
                    className={'m10-left '}
                    src={iconRightArrow} alt='fleche droite'>
                </img>
                {/* <i className="far fa-arrow-alt-circle-right"></i>  */}
            </button>
            </div>
        </div>
    </>
    :null}
    </div>
    
    )
}

export default IntroPage;
