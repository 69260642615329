import React from 'react';
import { NavigationContext } from '../../store/NavigationContext'
import { AuthContext } from '../../store/AuthContext'
import { BookContext } from '../../store/BookContext'
// import StoreLogin from '../../components/StoreLogin/StoreLogin'
import { useIdleTimer } from 'react-idle-timer'
// import HomeScreen from '../../components/HomeScreen/HomeScreen';
import styles from './LandingPage.module.css';
import { axiosConfig } from '../../utils/axiosConfig';
import iconAccept from '../../assets/icon-accepter.svg'

function LandingPage() {
    const { navigationState, navigationDispatch } = React.useContext(NavigationContext)
    const { authState, authDispatch } = React.useContext(AuthContext);
    const { bookDispatch } = React.useContext(BookContext);
    const [ store ] = React.useState('3141');
    const [ query ] = React.useState('');
    const [ error, setError ] = React.useState('');

    React.useEffect(() => {
        if(!authState.store._id){
            axiosConfig.post(`${process.env.REACT_APP_API_URL}/apiFront/store/login`,
                { "code": store }
            )
            .then(resp=>{
                    if (resp.data.message) {
                        setError(resp.message)
                    }else{
                        authDispatch({ type:'storeAuth', payload: resp.data})
                        navigationDispatch({ type:'storeLoggedIn' })
                    }
                })
            .catch(error => {
                setError('Erreur de connexion')
                console.log(error);
            });
        }

    }, [authState.store._id, query, authDispatch, navigationDispatch,setError,store])
    const handleOnIdle = () => {
        // console.log("handleonIdle")
        if(navigationState.displayLanding){
            navigationDispatch({ type: 'displayIntroPage', status:true, page:1})
        }
      }
      useIdleTimer({
        timeout: 1000 * 10 ,
        // timeout: 500 * 10 ,
        onIdle: handleOnIdle,
      })
    
    // React.useEffect(() => {
    //     navigationDispatch({ type:'storeLoggedIn' })
    // }, [navigationDispatch])
return (
    <>
        {/* {authState.store === null ?

            <StoreLogin/>
        : */}
            {/* <>
             {navigationState.displayHomeScreen ?
                <HomeScreen/>
                :*/}
                {navigationState.displayLanding ?  
                    <div className={"container-fluid pageContainer " + styles.column}>
                        
                        {error !== '' ?

                            <span className={"alert alert-danger " + styles.error} role="alert">
                                {error}
                            </span>

                        :

                            <div className={styles.btnBoxAll}>
                                <div className={styles.btnBox + " "+ styles.rightAlign}>
                                    {/* <h1>Reprise de livres d'occasion</h1> */}
                                
                                    <button
                                        className={styles.bigBtn}
                                        onClick={() => navigationDispatch({ type: 'landingGive'})}
                                    >
                                        Je donne
                                    </button>
                                    <button
                                        className="newBtn grayDarkDecitre white"
                                        onClick={() => navigationDispatch({ type: 'displayIntroPage', status:true, page:2})}
                                    >comment ça marche</button>
                                </div>
                                
                                <div className={styles.btnBox +" "+ styles.leftAlign}>
                                    <button
                                        className={styles.bigBtn}
                                        onClick={() => navigationDispatch({ type: 'landingSell'})}
                                    >
                                    J'échange contre des bons d'achat
                                    </button>
                                    <button
                                        className={"newBtn grayDarkDecitre white " + styles.leftAlign}
                                        onClick={() => navigationDispatch({ type: 'displayIntroPage', status:true, page:3})}
                                    >les livres acceptés</button>
                                </div>
                            </div>

                        }
                        
                    </div>

                :navigationState.displayLandingGive ?

                    <div className={"container-fluid pageContainer " }>
                        <div className={"row " + styles.giveText}>
                            <div className={ styles.rowGiven }>
                                {/* {authState.store.givenSentence?<h4 className={styles.titleGivenSentence}>{authState.store.givenSentence}</h4>:null}
                                {authState.store.givenSentence2?<h4 className={styles.titleGivenSentence}>{authState.store.givenSentence2}</h4>:null} */}
                                <h4 className={styles.titleGenerosity}>Merci de votre générosité !</h4>
                                <p className={styles.helpText}>Vous pouvez déposer vos livres dans le bac prévu à cet effet.</p>
                                <p className={styles.helpText}>N’hésitez pas à demander de l’aide à un vendeur si besoin.</p>
                            </div>
                            <button
                                    // className="btn btn-m btn-blue"
                                    className={"newBtn white greenDecitre"}
                                    onClick={ () => {
                                        authDispatch({ type:'endOfSale' });
                                        navigationDispatch({ type:'storeLoggedIn' });
                                        bookDispatch({ type:'reset' })
                                        }}
                                >Terminer</button>
                        </div>
                    </div>

                :navigationState.displayLandingSell ?

                    <div className={"container-fluid pageContainer " }>
                        <div className={"row " + styles.row}>

                                {/* <h2>Conditions Générales d’Utilisation et d’Achat d’Occasion</h2> */}
                                
                                <div className={'container ' + styles.text}>
                                    <h4 className={styles.textTitle}>Il vous sera nécessaire de saisir une pièce d’identité pour finaliser la vente </h4>
                                    <div dangerouslySetInnerHTML={{__html: authState.store.cgu}} />
                                </div>
                                <div className={styles.btnBoxInLine + " " + styles.rightAlign}>
                                    <button
                                        // className="btn btn-s btn-orange"
                                        className="newBtn grayDarkDecitre white "
                                        onClick={() => navigationDispatch({ type: 'termsOfUseAccept'})}
                                    >
                                    <img className={styles.iconAccept} src={iconAccept} alt='icone acceptation'/>
                                        Accepter les conditions
                                    </button>
                                </div>
                               

                        </div>
                    </div>

                :null}
            </>
         /* } */
    // </>
  );
}

export default LandingPage;
