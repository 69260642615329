import React from 'react'

export function useIsNetworkAvailable() {
    const [state, setState] = React.useState(
      window.navigator.onLine ? "online" : "offline"
    );
    const setOnline = () => setState(true);
    const setOffline = () => setState(false);
  
    React.useEffect(() => {
      window.addEventListener("online", setOnline);
      window.addEventListener("offline", setOffline);
  
      return () => {
        window.removeEventListener("online", setOnline);
        window.removeEventListener("offline", setOffline);
      };
    }, []);
  
    return state;
  }
  