import React from 'react';
import styles from './Loader.module.css';

import { useAxiosLoader } from '../../utils/axiosConfig'

function Loader() {
    const [loading] = useAxiosLoader();
    // console.log(loading)

  return (
      <>
        {loading ?
            <div className={styles.overlay}>
                <div className={styles.overlay__inner}>
                    <div className={styles.overlay__content}><div className={styles.loader}>Loading...</div></div>
                </div>
            </div>
        :null}
    </>
  );
}

export default Loader;
