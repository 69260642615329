import React from 'react';

const BookContext = React.createContext();
const localState = JSON.parse(localStorage.getItem('bookState'));

const initialState = {
    book: null,
    lastAction: null,
    cart: [],
}

function bookReducer(state, action){
    switch (action.type) {
        case 'reset': {
            localStorage.removeItem("bookState")
            return initialState
        }
        case 'currentBook': {
            const updatedBook = action.payload;
            updatedBook.id = Date.now();
            return {
                ...state,
                book: updatedBook
            }
        }
        case 'sellBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'sold'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'SELL',
            }
        }
        case 'giveBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'given'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'GIVE',
            }
        }
        case 'keepBook': {
            const updatedCart = [...state.cart];
            const updatedBook = {...state.book, 'userChoice': 'kept'}
            updatedCart.push({ ...updatedBook});
            return {
                ...state,
                book: null,
                cart: updatedCart,
                lastAction: 'KEEP',
            }
        }
        case 'deleteBook': {
            const oldCart = [...state.cart];
            const newCart = oldCart.filter(cartItem => cartItem.id !== action.payload);
            return {
                ...state,
                cart: newCart,
            }
        }
        
        default: {
            return state;
        }
    }
}

function BookProvider({children}) {
    const [bookState, bookDispatch] = React.useReducer(bookReducer, localState || initialState)

    React.useEffect(() => {
        localStorage.setItem('bookState', JSON.stringify(bookState))
    }, [bookState])

    return (
      <BookContext.Provider value={{bookState, bookDispatch}}>
          {children}
      </BookContext.Provider>
    )
}

export { BookProvider, BookContext }