import { useState, useCallback, useMemo, useEffect, useContext } from 'react'
import axios from "axios"

import { AuthContext } from '../store/AuthContext'

const axiosConfig = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    timeout: 10000
});

const scanAxios = axios.create({
    baseURL: `${process.env.REACT_APP_API_PRICING_URL}`,
    timeout: 10000,
});
    
const useAxiosLoader = () => {
    const { authState } = useContext(AuthContext);
    const token = authState.store ? authState.store.token : null;

    const [counter, setCounter] = useState(0);
    //   console.log('useAxios')
    const inc = useCallback(() => setCounter(counter => counter + 1), [
      setCounter
    ]); // add to counter
    const dec = useCallback(() => setCounter(counter => counter - 1), [
      setCounter
    ]); // remove from counter
    const addToken = useCallback((url) => { 
      if(url === `${process.env.REACT_APP_API_URL}`) return({ Authorization: `Bearer ${token}` }) 
    },[token]); 
  
    const interceptors = useMemo(
      () => ({
        request: config => {
          inc();
          Object.assign(config.headers.common, addToken(config.baseURL))
          return config;
        },
        response: response => {
          dec();
          // console.log('res : ' + response);
          return response;
        },
        error: error => {
          dec();
          // alert('Erreur de connexion')
          // console.log('log : ' + error);
          return Promise.reject(error);
        }
      }),
      [inc, dec, addToken]
    ); // create the interceptors
    
    useEffect(() => {
      // add request interceptors
      const reqInterceptor = scanAxios.interceptors.request.use(interceptors.request, interceptors.error);
      // add response interceptors
      const resInterceptor = scanAxios.interceptors.response.use(interceptors.response, interceptors.error);

      // add request interceptors
      const reqInterceptorConfig = axiosConfig.interceptors.request.use(interceptors.request, interceptors.error);
      // add response interceptors
      const resInterceptorConfig = axiosConfig.interceptors.response.use(interceptors.response, interceptors.error);

      return () => {
        // remove all intercepts when done
        scanAxios.interceptors.request.eject(reqInterceptor);
        scanAxios.interceptors.response.eject(resInterceptor);

        axiosConfig.interceptors.request.eject(reqInterceptorConfig);
        axiosConfig.interceptors.response.eject(resInterceptorConfig);
      };
    }, [interceptors, token]);
    
    return [counter > 0];
  };


export { axiosConfig, scanAxios, useAxiosLoader}
